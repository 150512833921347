import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";
import { apiRequest } from "./../util/util";

function MarketplaceItemForm({ item, onDone, prepopulatedImage }) {
  const auth = useAuth();
  const history = useHistory();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [state, setState] = useState(item?.state || "active");
  const [files, setFiles] = useState([]);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: item || {},
  });

  useEffect(() => {
    if (prepopulatedImage) {
      // Log the URL to ensure it's correct
      console.log("Fetching image from URL:", prepopulatedImage);

      // Convert the prepopulated image URL to a File object if necessary
      fetch(prepopulatedImage)
        .then((res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch image: ${res.statusText}`);
          }
          return res.blob();
        })
        .then((blob) => {
          const file = new File([blob], "prepopulated-image.jpg", { type: blob.type });
          setFiles([file]);
        })
        .catch((error) => {
          console.error("Error fetching image:", error);
        });
    }
  }, [prepopulatedImage]);

  const onSubmit = async (data) => {
    setPending(true);
    setError(null);

    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("price", data.price);
    formData.append("tags", data.tags || "");
    formData.append("state", state);
    files.forEach((file, index) => formData.append("images", file)); // Ensure field name matches backend

    try {
      const endpoint = item ? `marketplace-update-item?id=${item.id}` : "marketplace-create-item";
      const method = item ? "PUT" : "POST";
      await apiRequest(endpoint, method, formData);
      onDone();
      history.push("/marketplace");
    } catch (err) {
      setError(err.message);
    } finally {
      setPending(false);
    }
  };

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      {error && <div className="text-red-500">{error}</div>}
      <TextField
        label="Title"
        name="title"
        placeholder="Enter item title"
        error={errors.title}
        inputRef={register({ required: "Title is required" })}
      />
      <TextField
        label="Description"
        name="description"
        placeholder="Enter item description"
        error={errors.description}
        inputRef={register({ required: "Description is required" })}
        type="textarea"
      />
      <TextField
        label="Price"
        name="price"
        type="number"
        placeholder="Enter price"
        error={errors.price}
        inputRef={register({ required: "Price is required", min: 0 })}
      />
      <TextField
        label="Tags (comma-separated)"
        name="tags"
        placeholder="e.g., chair, vintage"
        error={errors.tags}
        inputRef={register()}
      />
      <div>
        <label className="block mb-1 font-medium">Images</label>
        <input
          type="file"
          multiple
          accept="image/*"
          name="images" // Match formidable field name
          onChange={handleFileChange}
          className="border p-2 rounded"
        />
        {files.length > 0 && (
          <div className="mt-2">
            <p>Current Images:</p>
            {files.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Current ${index}`}
                className="w-20 h-20 object-cover inline-block mr-2"
              />
            ))}
          </div>
        )}
      </div>
      <div>
        <label className="block mb-1 font-medium">State</label>
        <div className="flex space-x-2">
          <Button
            type="button"
            variant={state === "active" ? "primary" : "outline"}
            onClick={() => setState("active")}
          >
            Active
          </Button>
          <Button
            type="button"
            variant={state === "sold" ? "primary" : "outline"}
            onClick={() => setState("sold")}
          >
            Sold
          </Button>
        </div>
      </div>
      <div className="flex space-x-2">
        <Button type="button" onClick={onDone} variant="simple">Cancel</Button>
        <Button type="submit" disabled={pending}>
          {pending ? <LoadingIcon className="w-5" /> : item ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
}

export default MarketplaceItemForm;