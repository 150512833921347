import { useRef, useEffect } from "react";
import supabase from "./supabase";

export async function apiRequest(path, method = "GET", data, headers = {}) {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  const accessToken = session ? session.access_token : undefined;

  const defaultHeaders = {
    Authorization: `Bearer ${accessToken}`,
  };

  const response = await fetch(`/api/${path}`, {
    method: method,
    headers: data instanceof FormData 
      ? { ...defaultHeaders, ...headers } 
      : { "Content-Type": "application/json", ...defaultHeaders, ...headers },
    body: data instanceof FormData ? data : data ? JSON.stringify(data) : undefined,
  });

  if (!response.ok) {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      throw new Error(json.message || json.error || JSON.stringify(json));
    } catch (e) {
      throw new Error(text || `HTTP error! status: ${response.status}`);
    }
  }

  const json = await response.json();
  return json;
}

export function apiRequestExternal(url, method = "GET", data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}
