import React from "react";
import { Helmet } from "react-helmet";
import { useRouter } from "./../util/router.js";

function Meta(props) {
  const router = useRouter();

  // Default meta values
  const defaultMeta = {
    title: "FurniSnapp | AI-Powered Furniture Staging",
    description: "Transform your furniture flipping business with FurniSnapp. Use AI to instantly generate professionally staged photos, sell faster, and boost profits.",
    image: "/images/social-image.jpg", // Make sure this image exists in your public folder
    type: "website",
    keywords: "furniture staging, AI staging, furniture flipping, home decor, interior design, sell furniture online",
    canonical: "",
    ogType: "website",
    ogImage: "/images/social-image.jpg",
    ogUrl: "",
    twitterCard: "summary_large_image",
    twitterImage: "/images/social-image.jpg",
    structuredData: null,
  };

  // Merge default and custom meta
  const meta = { ...defaultMeta, ...props };

  // Determine canonical URL
  const canonicalUrl = meta.canonical || `https://furnisnapp.com${router.pathname}`;
  
  // Determine OG URL
  const ogUrl = meta.ogUrl || `https://furnisnapp.com${router.pathname}`;
  
  // Determine OG image URL (ensure it's absolute)
  const ogImage = meta.ogImage.startsWith('http') 
    ? meta.ogImage 
    : `https://furnisnapp.com${meta.ogImage}`;
    
  // Determine Twitter image URL (ensure it's absolute)
  const twitterImage = meta.twitterImage.startsWith('http') 
    ? meta.twitterImage 
    : `https://furnisnapp.com${meta.twitterImage}`;

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={meta.ogType || meta.type} />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={ogImage} />

      {/* Twitter */}
      <meta property="twitter:card" content={meta.twitterCard} />
      <meta property="twitter:url" content={ogUrl} />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta property="twitter:image" content={twitterImage} />

      {/* Additional SEO-friendly meta tags */}
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="author" content="FurniSnapp" />
      
      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Structured Data */}
      {meta.structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(meta.structuredData)}
        </script>
      )}
    </Helmet>
  );
}

export default Meta;