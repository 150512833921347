import {
  useQuery,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import supabase from "./supabase";
import { useAuth } from "./auth";

// React Query client
const client = new QueryClient();

/**** USERS ****/

// Fetch user data
export function useUser(uid) {
  return useQuery(
    ["user", { uid }],
    () => supabase.from("users").select("*").eq("id", uid).single().then(handle),
    { enabled: !!uid }
  );
}

// Fetch user data (non-hook)
export function getUser(uid) {
  return supabase.from("users").select("*").eq("id", uid).single().then(handle);
}

// Update an existing user
export async function updateUser(uid, data) {
  const response = await supabase.from("users").update(data).eq("id", uid).then(handle);
  await client.invalidateQueries(["user", { uid }]);
  return response;
}

// Create a new user
export async function createUser(uid, data) {
  const response = await supabase.from("users").insert([{ id: uid, ...data }]).then(handle);
  return response;
}

/**** ITEMS ****/

// Fetch item data
export function useItem(id) {
  return useQuery(
    ["item", { id }],
    () => supabase.from("items").select().eq("id", id).single().then(handle),
    { enabled: !!id }
  );
}

// Fetch all items by owner
export function useItemsByOwner(owner) {
  return useQuery(
    ["items", { owner }],
    () => supabase.from("items").select().eq("owner", owner).order("createdAt", { ascending: false }).then(handle),
    { enabled: !!owner }
  );
}

// Create a new item
export async function createItem(data) {
  const response = await supabase.from("items").insert([data]).then(handle);
  await client.invalidateQueries(["items"]);
  return response;
}

// Update an item
export async function updateItem(id, data) {
  const response = await supabase.from("items").update(data).eq("id", id).then(handle);
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

// Delete an item
export async function deleteItem(id) {
  const response = await supabase.from("items").delete().eq("id", id).then(handle);
  await Promise.all([
    client.invalidateQueries(["item", { id }]),
    client.invalidateQueries(["items"]),
  ]);
  return response;
}

/**** FURNITURE ITEMS ****/

// Fetch all active furniture items
export function useFurnitureItems({ search, tag } = {}) {
  return useQuery(
    ["furniture_items", { search, tag }],
    async () => {
      let query = supabase.from("furniture_items").select("*").eq("state", "active").order("created_at", { ascending: false });
      if (search) query = query.ilike("title", `%${search}%`);
      if (tag) query = query.contains("tags", [tag]);
      return query.then(handle);
    },
    { enabled: true }
  );
}

// Fetch furniture items by owner
export function useFurnitureItemsByOwner(owner) {
  return useQuery(
    ["furniture_items", { owner }],
    () => supabase.from("furniture_items").select("*").eq("owner", owner).order("created_at", { ascending: false }).then(handle),
    { enabled: !!owner }
  );
}

/**** IMAGE EDITS ****/

// Create a new image edit
export async function createImageEdit(data) {
  const response = await supabase.from("image_edits").insert([data]).then(handle);
  await client.invalidateQueries(["image_edits"]);
  return response;
}

// Fetch all image edits for the current user
export function useImageEdits() {
  const auth = useAuth();
  return useQuery(
    ["image_edits", { user_id: auth.user?.uid }],
    () => supabase.from("image_edits").select("*").eq("user_id", auth.user.uid).order("created_at", { ascending: false }).then(handle),
    { enabled: !!auth.user?.uid }
  );
}

/**** HELPERS ****/

// Get response data or throw error if there is one
function handle(response) {
  if (response.error) throw response.error;
  return response.data;
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}

export { client };
