import React from "react";
import Section from "./Section";
import Button from "./Button";
import { Link } from "./../util/router";

function CtaSection(props) {
  return (
    <Section
      size={props.size}
      bgColor="bg-white"
      textColor="text-black"
      className="pb-0"
    >
      <div className="container mx-auto">
        <div className="bg-[#1a1a1a] text-white rounded-lg shadow-lg p-12 md:p-16 max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold">Try FurniSnapp today</h1>
            <p className="mt-6 text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto">See how AI autostaging can boost sales and save you time. Join our community of successful furniture flippers and transform your business.</p>
            <Button
              component={Link}
              to="/pricing"
              className="mt-8 bg-blue-600 text-white hover:bg-blue-700 text-lg md:text-xl px-8 py-3 md:px-10 md:py-4"
            >
              Try now
            </Button>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default CtaSection;