import React, { useState, useEffect } from "react";
import { apiRequest } from "./../util/util";
import Button from "./Button";
import MarketplaceItemForm from "./MarketplaceItemForm";
import { useAuth } from "./../util/auth";
import supabase from "./../util/supabase";

function MarketplaceItems() {
  const auth = useAuth();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [tagFilter, setTagFilter] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  useEffect(() => {
    fetchItems();
  }, [search, tagFilter]);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const query = new URLSearchParams({ search, tag: tagFilter }).toString();
      const response = await apiRequest(`marketplace-get-items?${query}`);
      setItems(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getThumbnailUrl = (imageUrl) => {
    const path = imageUrl.split("/furniture-images/")[1];
    return supabase.storage
      .from("furniture-images")
      .getPublicUrl(path, {
        transform: {
          width: 128,
          height: 128,
          resize: "cover",
        },
      }).data.publicUrl;
  };

  const handleFormDone = () => {
    setShowForm(false);
    setEditingItem(null);
    fetchItems();
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between mb-4">
        <input
          type="text"
          placeholder="Search items..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="border p-2 rounded"
        />
        {auth.user && (
          <Button onClick={() => setShowForm(true)}>Add Item</Button>
        )}
      </div>
      {loading && <div>Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      {(showForm || editingItem) && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full">
            <MarketplaceItemForm
              item={editingItem}
              onDone={handleFormDone}
            />
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {items.map((item) => (
          <div key={item.id} className="border p-4 rounded">
            <h3 className="text-lg font-bold">{item.title}</h3>
            <p>{item.description}</p>
            <p>Price: ${item.price}</p>
            <p>Tags: {item.tags.join(", ")}</p>
            {item.images[0] && (
              <img src={getThumbnailUrl(item.images[0])} alt={item.title} className="w-32 h-32 object-cover mt-2" />
            )}
            {auth.user && auth.user.id === item.owner && (
              <Button onClick={() => setEditingItem(item)} className="mt-2">Edit</Button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MarketplaceItems;
