import React from "react";
import Button from "./Button";
import { useAuth } from "../util/auth";

export function HeroSection({ onShopNowClick }) {
  return (
    <div className="flex flex-col items-center justify-center py-12 bg-white">
      <div className="flex flex-col items-start justify-between w-full max-w-7xl p-6 bg-white rounded-lg lg:flex-row">
        <div className="flex flex-col space-y-3 lg:w-1/2">
          <h1 className="text-3xl font-bold leading-tight text-gray-900 lg:text-4xl">
            The furniture marketplace built for <span className="text-blue-600">flippers</span>, by{" "}
            <span className="text-blue-600">flippers</span>
          </h1>
          <p className="text-base text-gray-600 lg:text-lg">
            FurniSnapp is the one-stop marketplace for flippers to buy and sell redesigned furniture
          </p>
          <div className="flex space-x-4 mt-3">
            <Button size="md" onClick={onShopNowClick}>Shop now</Button>
            <Button variant="outline" size="md">Learn more</Button>
          </div>
        </div>
        <div className="mt-6 lg:mt-0 lg:ml-6 lg:w-1/2">
          <img
            src="/mplace1.1.jpeg"
            alt="Stylish furniture"
            className="rounded-lg self-center w-full h-auto"
            width="2400"
            height="1600"
            style={{ aspectRatio: "2400/1600", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
}

export function QuoteSection() {
  return (
    <div className="flex items-center justify-center py-16 bg-black">
      <blockquote className="text-center text-white max-w-3xl px-4">
        <p className="text-2xl italic">
          "Your home should tell the story of who you are, and be a collection of what you love."
        </p>
        <footer className="mt-4 text-lg">
          - Nate Berkus
        </footer>
      </blockquote>
    </div>
  )
}

export function GallerySection() {
  const auth = useAuth();

  return (
    <div className="bg-[#111111] text-white p-8">
      <div className="max-w-5xl mx-auto">
        <header className="mb-8">
          <p className="text-sm text-muted-foreground uppercase">Gallery</p>
          <h1 className="text-4xl font-bold">Our furniture, in real life</h1>
          <div className="flex justify-between mt-4">
            <p className="text-muted-foreground max-w-md">
              From stylish and modern sofas to classic dining tables, our gallery is a visual treat for anyone looking
              for inspiration and ideas for their home decor.
            </p>
            <p className="text-muted-foreground max-w-md">
              See below for a collection of photographs from real homes that highlight the quality, design, and
              craftsmanship of our furniture pieces.
            </p>
          </div>
        </header>
        <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {[1, 2, 3, 4, 5, 6].map((num) => (
            <img
              key={num}
              src={`/mplace4.${num}.jpeg`}
              alt={`Gallery ${num}`}
              className="w-full h-auto rounded-lg"
              width="300"
              height="300"
              style={{ aspectRatio: "300/300", objectFit: "cover" }}
            />
          ))}
        </main>
      </div>
    </div>
  );
}

export function DesignerSection() {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center min-h-screen p-4 bg-white">
      <div className="w-full md:w-1/2 p-4">
        <img
          src="/mplace5.1.jpeg"
          alt="Interior design"
          className="w-full h-full object-cover rounded-lg"
          width="500"
          height="500"
          style={{ aspectRatio: "500/500", objectFit: "cover" }}
        />
      </div>
      <div className="w-full md:w-1/2 p-4">
        <h4 className="text-sm font-semibold text-gray-500 uppercase mb-2">Online Exclusive</h4>
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Unique designs</h2>
        <p className="text-gray-700 mb-4">
          Our designer series will feature a range of furniture pieces, from sophisticated living room sofas to
          functional and stylish office furniture. Each piece has been carefully designed and crafted to ensure maximum
          comfort, durability, and aesthetics.
        </p>
        <p className="text-gray-700">
          Our exclusive collaboration with 45 designers represents a significant milestone in our commitment to
          providing our customers with the best in furniture design.
        </p>
      </div>
    </div>
  )
}

export function TestimonialsSection() {
  const reviews = [
    {
      content: "I absolutely loved the sofa that I ordered. Customer service was amazing.",
      name: "Jeff Conner",
      location: "New York, NY",
      initials: "JC",
      image: "/mplace6.1.jpeg"
    },
    {
      content: "Shipment arrived in the color and appearance I expected and in perfect condition. The chairs look exactly as they are shown on the website. I love them.",
      name: "Emma Pérez",
      location: "Los Angeles, CA",
      initials: "EP",
      image: "/mplace6.2.jpeg"
    },
    {
      content: "The furniture is so well made and looks incredible. Customer service is so easy to get a hold of and helpful.",
      name: "Raj Mishra",
      location: "Austin, TX",
      initials: "RM",
      image: "/mplace6.3.jpeg"
    },
    {
      content: "Perfect experience. Right on time, and the overall quality (design, details, etc) was over my expectations.",
      name: "John Freidman",
      location: "Philadelphia, PA",
      initials: "JF",
      image: "/mplace6.4.jpeg"
    },
    {
      content: "Absolutely amazing customer service and delivery team. 10/10!",
      name: "Francis Gaddi",
      location: "San Francisco, CA",
      initials: "FG",
      image: "/mplace6.5.jpeg"
    },
    {
      content: "I've received so many compliments for my Soren Bergman chair! Really impressed by the quality of the furniture as well as the experience.",
      name: "Aya Williams",
      location: "Chicago, IL",
      initials: "AW",
      image: "/mplace6.6.jpeg"
    }
  ];

  return (
    <div className="px-4 py-8 mx-auto max-w-7xl">
      <h2 className="text-sm font-semibold text-gray-500">REVIEWS</h2>
      <h1 className="mt-2 text-4xl font-bold">What people are saying</h1>
      <div className="grid gap-6 mt-8 sm:grid-cols-2 lg:grid-cols-3">
        {reviews.map((review, index) => (
          <div key={index} className="p-6 bg-white rounded-lg shadow-md">
            <p className="text-lg">{review.content}</p>
            <div className="flex items-center mt-4">
              <div className="w-10 h-10 rounded-full overflow-hidden">
                <img 
                  src={review.image} 
                  alt={review.name} 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="ml-4">
                <p className="font-semibold">{review.name}</p>
                <p className="text-sm text-gray-500">{review.location}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 