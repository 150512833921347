import React, { useState, useEffect } from "react";
import Button from "./Button";
import axios from "axios";
import { useAuth } from "./../util/auth";
import { createImageEdit } from "./../util/db";
import { useUser, updateUser } from "./../util/db";
import { HandThumbUpIcon, HandThumbDownIcon } from '@heroicons/react/24/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useRouter } from "./../util/router";

export default function ImageEditingSection() {
  const auth = useAuth();
  const router = useRouter();
  const { data: user } = useUser(auth.user?.uid);
  const [inputImages, setInputImages] = useState([]);
  const [outputImages, setOutputImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [processingImages, setProcessingImages] = useState({});
  const [credits, setCredits] = useState(0);
  const [customPrompt, setCustomPrompt] = useState("");
  const [showFeedbackIcons, setShowFeedbackIcons] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedFurnitureType, setSelectedFurnitureType] = useState("dresser");
  const [selectedDesignStyle, setSelectedDesignStyle] = useState("modern");
  const [promptMode, setPromptMode] = useState("auto"); // 'auto' or 'custom'
  const [autoPrompt, setAutoPrompt] = useState("");
  const [isEditingAutoPrompt, setIsEditingAutoPrompt] = useState(false);
  const isFreeTrial = false; //auth.user ? auth.user.isFreeTrial : true;

  const furnitureTypes = [
    { id: "dresser", label: "Dresser/Cabinet" },
    { id: "sofa", label: "Sofa/Couch" },
    { id: "table", label: "Dining Table" },
    { id: "chair", label: "Accent Chair" },
    { id: "coffee", label: "Coffee Table" },
    { id: "bed", label: "Bed" },
    { id: "desk", label: "Desk" },
    { id: "bookcase", label: "Bookcase/Shelf" }
  ];

  const designStyles = [
    { id: "modern", label: "Modern" },
    { id: "midcentury", label: "Mid-Century Modern" },
    { id: "farmhouse", label: "Farmhouse" },
    { id: "industrial", label: "Industrial" },
    { id: "bohemian", label: "Bohemian" },
    { id: "traditional", label: "Traditional" },
    { id: "scandinavian", label: "Scandinavian" },
    { id: "coastal", label: "Coastal" }
  ];

  useEffect(() => {
    if (user) {
      setCredits(user.creditsRemaining);
      console.log("Credits:", user.creditsRemaining);
    }
  }, [user]);

  useEffect(() => {
    if (promptMode === 'auto') {
      const newPrompt = `${getDesignStylePrompt(selectedDesignStyle)} for a ${getFurnitureTypePrompt(selectedFurnitureType)}`;
      setAutoPrompt(newPrompt);
    }
  }, [selectedFurnitureType, selectedDesignStyle, promptMode]);

  const getFurnitureTypePrompt = (type) => {
    switch (type) {
      case "dresser":
        return "bedroom with white walls, area rug, bedside lamps";
      case "sofa":
        return "living room with hardwood floors, coffee table, wall art";
      case "table":
        return "dining area with pendant lighting, area rug, window view";
      case "chair":
        return "cozy corner with side table, reading lamp, bookshelf";
      case "coffee":
        return "living room with sectional sofa, area rug, large windows";
      case "bed":
        return "bedroom with nightstands, soft lighting, window treatments";
      case "desk":
        return "home office with bookshelves, task lighting, minimalist decor";
      case "bookcase":
        return "study area with warm lighting, comfortable seating, wood accents";
      default:
        return "stylish interior with complementary decor";
    }
  };

  const getDesignStylePrompt = (style) => {
    switch (style) {
      case "modern":
        return "minimalist space, white walls, clean lines, statement lighting";
      case "midcentury":
        return "mid-century modern room, walnut tones, geometric patterns, brass accents";
      case "farmhouse":
        return "rustic farmhouse, shiplap walls, neutral colors, vintage accessories";
      case "industrial":
        return "industrial loft, exposed brick, metal elements, Edison bulbs";
      case "bohemian":
        return "boho space, layered textiles, plants, eclectic decor";
      case "traditional":
        return "classic room, rich colors, symmetrical layout, elegant details";
      case "scandinavian":
        return "Scandinavian interior, light woods, white walls, minimal decor";
      case "coastal":
        return "coastal style room, blue accents, natural textures, light and airy";
      default:
        return "stylish interior";
    }
  };

  const getBackgroundPrompt = () => {
    if (promptMode === "custom") {
      return customPrompt || "Custom background prompt";
    } else {
      return autoPrompt;
    }
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      // Filter out any invalid files
      const validFiles = files.filter(file => file instanceof File && file.type.startsWith('image/'));
      
      if (validFiles.length === 0) {
        alert("No valid image files were selected. Please try again with image files.");
        return;
      }
      
      console.log("Adding valid files:", validFiles.length);
      
      setInputImages(prevImages => {
        const newImages = [...prevImages, ...validFiles];
        // Set the selected index to the last uploaded image
        setSelectedImageIndex(newImages.length - 1);
        return newImages;
      });
    }
  };

  const handleRemoveImage = (index) => {
    setInputImages(prevImages => {
      const newImages = [...prevImages];
      newImages.splice(index, 1);
      
      // Also remove the corresponding output image
      setOutputImages(prevOutputImages => {
        const newOutputImages = [...prevOutputImages];
        newOutputImages.splice(index, 1);
        return newOutputImages;
      });
      
      // Adjust selected index if needed
      if (newImages.length === 0) {
        // If all images are removed, reset output image as well
        setShowFeedbackIcons(false);
        setSelectedImageIndex(0);
      } else if (selectedImageIndex >= newImages.length) {
        // If the selected image is beyond the new array length, select the last image
        setSelectedImageIndex(newImages.length - 1);
      } else if (index === selectedImageIndex && newImages.length > 0) {
        // If we're removing the currently selected image, select the next one in the same position
        // or the last one if we're at the end
        setSelectedImageIndex(Math.min(selectedImageIndex, newImages.length - 1));
      }
      
      return newImages;
    });
  };

  const handleSelectImage = (index) => {
    setSelectedImageIndex(index);
  };

  const processImage = async (index) => {
    if (processingImages[index] || outputImages[index]) {
      return; // Skip if already processing or processed
    }

    if (credits <= 0) {
      alert("You need credits to generate images.");
      return;
    }

    // Mark this image as processing
    setProcessingImages(prev => ({ ...prev, [index]: true }));

    const backgroundPrompt = getBackgroundPrompt();

    try {
      const formData = new FormData();
      formData.append("imageFile", inputImages[index]);
      formData.append("background.prompt", backgroundPrompt);
      formData.append("background.negativePrompt", "no furniture");
      formData.append("referenceBox", "originalImage");

      const response = await axios.post('https://image-api.photoroom.com/v2/edit', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": process.env.REACT_APP_PHOTOROOM_API_KEY || 'b94a5245315ed554e4890492b3384aebc5d8d3b1',
        },
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'image/jpeg' });
      const imageUrl = URL.createObjectURL(blob);

      // Add watermark if the user is on a free trial
      let finalImageUrl;
      if (isFreeTrial) {
        finalImageUrl = await addWatermark(imageUrl);
      } else {
        finalImageUrl = imageUrl;
      }

      // Update the output images array
      setOutputImages(prevOutputImages => {
        const newOutputImages = [...prevOutputImages];
        newOutputImages[index] = finalImageUrl;
        return newOutputImages;
      });

      // Save the image edit to Supabase
      if (auth.user) {
        await saveImageEditToSupabase(finalImageUrl, backgroundPrompt, index);
      }

      setShowFeedbackIcons(true);
    } catch (error) {
      console.error(`Error generating image for index ${index}:`, error);
    } finally {
      // Mark this image as no longer processing
      setProcessingImages(prev => {
        const newProcessing = { ...prev };
        delete newProcessing[index];
        return newProcessing;
      });
    }
  };

  // Function to simulate progress over 30 seconds
  const simulateProgress = () => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + (100 / 30);
      });
    }, 1000);
    
    return interval;
  };

  const handleGenerateImage = async () => {
    console.log("Generate image called with:", {
      inputImagesLength: inputImages.length,
      selectedImageIndex,
      selectedImage: inputImages[selectedImageIndex]
    });

    if (inputImages.length === 0) {
      alert("Please upload at least one image first");
      return;
    }

    if (selectedImageIndex >= inputImages.length || !inputImages[selectedImageIndex]) {
      console.error("Selected image index is invalid:", selectedImageIndex, "Array length:", inputImages.length);
      alert("The selected image is not valid. Please select another image or upload a new one.");
      return;
    }

    if (credits <= 0) {
      router.push("/auth/signup");
      return;
    }

    setIsLoading(true);
    const progressInterval = simulateProgress();

    try {
      await processImage(selectedImageIndex);
      
      // Update user credits
      if (auth.user) {
        const newCredits = credits - 1;
        await updateUser(auth.user.uid, { creditsRemaining: newCredits });
        setCredits(newCredits);
      } else {
        setCredits(prevCredits => prevCredits - 1);
      }
      
      // Set progress to 100% when processing finishes
      setProgress(100);
    } finally {
      // Wait a short moment to show 100% before clearing
      setTimeout(() => {
        clearInterval(progressInterval);
        setIsLoading(false);
        setProgress(0);
      }, 500);
    }
  };

  const addWatermark = (imageUrl) => {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.onload = () => {
        console.log("Image loaded, adding watermark");
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        // Add watermark text
        ctx.font = 'bold 240px sans-serif';
        ctx.fillStyle = 'rgba(255, 0, 0, 0.7)';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Add stroke to the text
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.lineWidth = 3;
        ctx.strokeText('furnisnapp.com', canvas.width / 2, canvas.height - 300);

        // Fill text
        ctx.fillText('furnisnapp.com', canvas.width / 2, canvas.height - 300);

        resolve(canvas.toDataURL('image/jpeg'));
      };

      image.src = imageUrl;
    });
  };

  const saveImageEditToSupabase = async (outputImageUrl, backgroundPrompt, imageIndex) => {
    try {
      if (!auth.user) {
        console.log("No authenticated user, skipping save to Supabase");
        return;
      }
      
      if (inputImages.length === 0 || imageIndex >= inputImages.length) {
        console.error("No valid input image to save");
        return;
      }
      
      const selectedImage = inputImages[imageIndex];
      if (!(selectedImage instanceof File) || !selectedImage.type.startsWith('image/')) {
        console.error("Selected image is not a valid image file");
        return;
      }
      
      let inputImageUrl;
      try {
        inputImageUrl = URL.createObjectURL(selectedImage);
      } catch (error) {
        console.error("Error creating object URL for input image:", error);
        return;
      }
      
      const imageEditData = {
        user_id: auth.user.uid,
        input_image_url: inputImageUrl,
        output_image_url: outputImageUrl,
        background_style: backgroundPrompt,
      };

      await createImageEdit(imageEditData);
      console.log("Image edit saved to Supabase");
    } catch (error) {
      console.error("Error saving image edit to Supabase:", error);
    }
  };

  const handleDownload = () => {
    if (outputImages.length > 0 && selectedImageIndex < outputImages.length && outputImages[selectedImageIndex]) {
      const link = document.createElement('a');
      link.href = outputImages[selectedImageIndex];
      link.download = `staged_image_${selectedImageIndex + 1}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleShare = () => {
    if (outputImages.length > 0 && selectedImageIndex < outputImages.length && outputImages[selectedImageIndex]) {
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(outputImages[selectedImageIndex])}`;
      window.open(shareUrl, '_blank', 'width=600,height=400');
    }
  };

  const handleFeedback = (isPositive) => {
    console.log(isPositive ? "Positive feedback" : "Negative feedback");
    setShowFeedbackIcons(false);
    if (!isPositive) {
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSeeTips = () => {
    router.push("/tips");
  };

  const handleListOnMarketplace = () => {
    if (outputImages.length > 0 && selectedImageIndex < outputImages.length && outputImages[selectedImageIndex]) {
      router.push(`/marketplace?image=${encodeURIComponent(outputImages[selectedImageIndex])}`);
    }
  };

  const handleClearAllImages = () => {
    setInputImages([]);
    setOutputImages([]);
    setSelectedImageIndex(0);
    setShowFeedbackIcons(false);
  };

  const handleGenerateAllImages = async () => {
    const unprocessedIndices = inputImages
      .map((_, index) => index)
      .filter(index => !outputImages[index] && !processingImages[index]);
    
    if (unprocessedIndices.length === 0) {
      alert("All images have already been processed.");
      return;
    }
    
    if (credits < unprocessedIndices.length) {
      alert(`You need ${unprocessedIndices.length} credits to generate all remaining images. You currently have ${credits} credits.`);
      return;
    }
    
    const confirmGenerate = window.confirm(
      `This will generate images for all ${unprocessedIndices.length} unprocessed images and use ${unprocessedIndices.length} credits. Continue?`
    );
    
    if (!confirmGenerate) {
      return;
    }
    
    setIsLoading(true);
    const progressInterval = simulateProgress();
    
    try {
      // Start all image processing in parallel
      const processingPromises = unprocessedIndices.map(index => processImage(index));
      
      // Update credits
      if (auth.user) {
        const newCredits = credits - unprocessedIndices.length;
        await updateUser(auth.user.uid, { creditsRemaining: newCredits });
        setCredits(newCredits);
      } else {
        setCredits(prevCredits => prevCredits - unprocessedIndices.length);
      }
      
      // Wait for all processing to complete
      await Promise.all(processingPromises);
      
      // Set progress to 100% when processing finishes
      setProgress(100);
    } finally {
      // Wait a short moment to show 100% before clearing
      setTimeout(() => {
        clearInterval(progressInterval);
        setIsLoading(false);
        setProgress(0);
      }, 500);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-8 bg-gray-100">
      <div className="text-center mb-8">
        <p className="text-sm text-gray-500">HOW IT WORKS</p>
        <h1 className="text-3xl font-bold">
          3 easy steps to <span className="text-blue-600">instantly</span> stage your furniture
        </h1>
      </div>
      <div className="flex justify-around gap-x-20 mb-8">
        {[1, 2, 3].map((step, index) => (
          <div key={index} className="flex flex-col items-center">
            <div className="flex items-center justify-center w-12 h-12 border-2 border-black rounded-full">
              <span className="text-lg font-bold">{step}</span>
            </div>
            <p className="mt-2 text-center">
              {index === 0 ? "Upload your image" : index === 1 ? "Press \"Generate Now\"" : "Download your photo"}
            </p>
          </div>
        ))}
      </div>
      <div className="flex flex-col md:flex-row justify-around items-start gap-x-8 bg-gray-900 p-8 rounded-lg w-full max-w-7xl mx-auto">
        {/* Input Image Area */}
        <div className="flex flex-col w-full md:w-2/5">
          <div className="flex flex-col items-center justify-center w-full p-8 bg-white border border-gray-300 rounded-lg h-[400px] mb-4">
            {inputImages.length > 0 && selectedImageIndex < inputImages.length ? (
              (() => {
                const selectedImage = inputImages[selectedImageIndex];
                
                // Validate the selected image
                if (!(selectedImage instanceof File) || !selectedImage.type.startsWith('image/')) {
                  return (
                    <div className="text-center text-red-500">
                      <p>Invalid image selected. Please select another image or upload a new one.</p>
                    </div>
                  );
                }
                
                // Try to create object URL
                let imageUrl;
                try {
                  imageUrl = URL.createObjectURL(selectedImage);
                } catch (error) {
                  console.error("Error creating object URL for main image:", error);
                  return (
                    <div className="text-center text-red-500">
                      <p>Error displaying the selected image. Please try another image.</p>
                    </div>
                  );
                }
                
                return (
                  <img
                    src={imageUrl}
                    alt="Input"
                    className="max-w-full max-h-full object-contain"
                    onError={(e) => {
                      console.error("Error loading main image");
                      e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'/%3E%3Ccircle cx='8.5' cy='8.5' r='1.5'/%3E%3Cpolyline points='21 15 16 10 5 21'/%3E%3C/svg%3E";
                    }}
                  />
                );
              })()
            ) : (
              <div className="flex flex-col items-center text-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-gray-400 mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <p className="text-gray-500 mb-3">Upload multiple images of your furniture</p>
                <label className="cursor-pointer bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition">
                  Upload Images
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleImageUpload}
                    multiple
                  />
                </label>
              </div>
            )}
          </div>

          {/* Image thumbnails for multiple uploads */}
          {inputImages.length > 0 && (
            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-sm font-medium">Uploaded Images ({inputImages.length})</h3>
                <div className="flex space-x-3">
                  <label className="cursor-pointer text-blue-600 text-sm">
                    + Add More
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageUpload}
                      multiple
                    />
                  </label>
                  <button 
                    className="text-red-600 text-sm"
                    onClick={handleClearAllImages}
                  >
                    Clear All
                  </button>
                </div>
              </div>
              <div className="flex overflow-x-auto space-x-2 pb-2">
                {inputImages.map((img, index) => {
                  // Skip rendering if img is not a valid File object
                  if (!(img instanceof File) || !img.type.startsWith('image/')) {
                    console.warn("Invalid image at index", index, img);
                    return null;
                  }
                  
                  let thumbnailUrl;
                  try {
                    thumbnailUrl = URL.createObjectURL(img);
                  } catch (error) {
                    console.error("Error creating object URL for thumbnail:", error);
                    return null;
                  }
                  
                  const isProcessed = outputImages[index] !== undefined;
                  const isProcessing = processingImages[index];
                  
                  return (
                    <div 
                      key={index} 
                      className={`relative flex-shrink-0 w-16 h-16 border-2 rounded ${selectedImageIndex === index ? 'border-blue-500' : isProcessed ? 'border-green-500' : 'border-gray-200'}`}
                    >
                      <img
                        src={thumbnailUrl}
                        alt={`Thumbnail ${index + 1}`}
                        className={`w-full h-full object-cover rounded cursor-pointer ${isProcessing ? 'opacity-50' : ''}`}
                        onClick={() => handleSelectImage(index)}
                        onError={(e) => {
                          console.error("Error loading thumbnail image");
                          e.target.src = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='3' width='18' height='18' rx='2' ry='2'/%3E%3Ccircle cx='8.5' cy='8.5' r='1.5'/%3E%3Cpolyline points='21 15 16 10 5 21'/%3E%3C/svg%3E";
                        }}
                      />
                      {isProcessing && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 rounded">
                          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </div>
                      )}
                      {isProcessed && (
                        <div className="absolute bottom-0 right-0 bg-green-500 rounded-full w-4 h-4 flex items-center justify-center">
                          <svg className="h-3 w-3 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                      )}
                      <button
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage(index);
                        }}
                      >
                        ×
                      </button>
                    </div>
                  );
                })}
              </div>
              <p className="text-xs text-gray-500 mt-1">
                Click on a thumbnail to select it for editing. Each image generation costs 1 credit.
              </p>
            </div>
          )}
          <div className="mt-4 bg-gray-800 p-4 rounded-lg">
            <div className="flex justify-between mb-4">
              <button
                className={`px-4 py-2 rounded-lg ${promptMode === 'auto' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                onClick={() => setPromptMode('auto')}
              >
                Auto Prompt
              </button>
              <button
                className={`px-4 py-2 rounded-lg ${promptMode === 'custom' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                onClick={() => setPromptMode('custom')}
              >
                Custom Prompt
              </button>
            </div>
            
            {promptMode === 'auto' ? (
              <div className="space-y-4">
                <div>
                  <p className="text-white mb-2">Design Style:</p>
                  <div className="grid grid-cols-2 gap-2">
                    {designStyles.map((style) => (
                      <button
                        key={style.id}
                        className={`px-3 py-2 text-sm rounded-lg ${selectedDesignStyle === style.id ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                        onClick={() => setSelectedDesignStyle(style.id)}
                      >
                        {style.label}
                      </button>
                    ))}
                  </div>
                </div>
                
                <div>
                  <p className="text-white mb-2">Furniture Type:</p>
                  <div className="grid grid-cols-2 gap-2">
                    {furnitureTypes.map((type) => (
                      <button
                        key={type.id}
                        className={`px-3 py-2 text-sm rounded-lg ${selectedFurnitureType === type.id ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'}`}
                        onClick={() => setSelectedFurnitureType(type.id)}
                      >
                        {type.label}
                      </button>
                    ))}
                  </div>
                </div>
                
                <div className="bg-gray-700 p-3 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                    <p className="text-gray-300 text-sm">Generated Prompt:</p>
                    <button 
                      onClick={() => setIsEditingAutoPrompt(!isEditingAutoPrompt)}
                      className="text-blue-400 hover:text-blue-300 text-xs underline"
                    >
                      {isEditingAutoPrompt ? "Done Editing" : "Edit Prompt"}
                    </button>
                  </div>
                  
                  {isEditingAutoPrompt ? (
                    <textarea
                      value={autoPrompt}
                      onChange={(e) => setAutoPrompt(e.target.value)}
                      className="w-full px-3 py-2 text-black rounded-lg h-24 text-sm"
                      placeholder="Edit the generated prompt"
                    />
                  ) : (
                    <p className="text-white text-sm italic">{autoPrompt}</p>
                  )}
                </div>
              </div>
            ) : (
              <div className="mt-2">
                <textarea
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  placeholder="Enter custom background prompt"
                  className="w-full px-3 py-2 text-black rounded-lg h-32"
                />
              </div>
            )}
          </div>
        </div>

        {/* Generate Button and Credits */}
        <div className="flex flex-col items-center justify-center w-full md:w-1/5 h-[400px]">
          <div className="flex flex-col items-center justify-center space-y-4">
            <Button
              className={`px-6 py-3 text-lg font-semibold ${credits > 0 ? "bg-green-600" : "bg-blue-600"} text-white`}
              onClick={credits > 0 ? handleGenerateImage : () => router.push("/auth/signup")}
              disabled={isLoading || (inputImages.length === 0 && credits > 0) || (selectedImageIndex < inputImages.length && outputImages[selectedImageIndex] !== undefined)}
            >
              {isLoading ? "Processing..." : credits > 0 ? "Generate Selected" : "Get Free Credits"}
            </Button>
            
            {isLoading && (
              <div className="w-full mt-2">
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-linear" 
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <p className="text-xs text-white mt-1 text-center">{Math.round(progress)}% complete</p>
              </div>
            )}
            
            {inputImages.length > 1 && credits > 0 && (
              <Button
                className="px-6 py-2 text-sm font-semibold bg-blue-600 text-white"
                onClick={handleGenerateAllImages}
                disabled={isLoading || inputImages.length === 0 || inputImages.every((_, index) => outputImages[index] !== undefined)}
              >
                Generate All ({inputImages.filter((_, index) => outputImages[index] === undefined).length})
              </Button>
            )}
            
            <div className="text-center">
              <p className="text-white">
                Credits Available: <span className="font-bold">{credits}</span>
              </p>
              <p className="text-xs text-gray-400 mt-1">
                Each image costs 1 credit to generate
              </p>
            </div>
            <button 
              onClick={() => router.push("/tips")}
              className="text-blue-400 hover:text-blue-300 text-sm underline"
            >
              See Prompt Tips
            </button>
          </div>
        </div>

        {/* Output Image Area */}
        <div className="flex flex-col w-full md:w-2/5">
          <div className="flex flex-col items-center justify-center w-full p-8 bg-white border border-gray-300 rounded-lg h-[400px] mb-4">
            {outputImages.length > 0 && selectedImageIndex < outputImages.length && outputImages[selectedImageIndex] ? (
              <img
                src={outputImages[selectedImageIndex]}
                alt="Output"
                className="max-w-full max-h-full object-contain"
              />
            ) : inputImages.length > 0 && selectedImageIndex < inputImages.length ? (
              <div className="relative w-full h-full flex items-center justify-center">
                {(() => {
                  try {
                    const originalImageUrl = URL.createObjectURL(inputImages[selectedImageIndex]);
                    return (
                      <>
                        <img
                          src={originalImageUrl}
                          alt="Original"
                          className="max-w-full max-h-full object-contain opacity-50"
                        />
                        {processingImages[selectedImageIndex] && (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className="bg-black bg-opacity-30 rounded-full p-4">
                              <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>
                            </div>
                          </div>
                        )}
                        {!processingImages[selectedImageIndex] && !outputImages[selectedImageIndex] && (
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className="bg-black bg-opacity-50 text-white px-4 py-2 rounded">
                              Click "Generate Selected" to process this image
                            </div>
                          </div>
                        )}
                      </>
                    );
                  } catch (error) {
                    console.error("Error creating object URL for original image:", error);
                    return <p className="text-gray-500">Error displaying original image</p>;
                  }
                })()}
              </div>
            ) : (
              <p className="text-gray-500">Output Image here</p>
            )}
          </div>
          {showFeedbackIcons && (
            <div className="flex justify-center space-x-4 mb-4">
              <button
                onClick={() => handleFeedback(true)}
                className="p-2 bg-green-500 rounded-full hover:bg-green-600 transition-colors"
              >
                <HandThumbUpIcon className="h-6 w-6 text-white" />
              </button>
              <button
                onClick={() => handleFeedback(false)}
                className="p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
              >
                <HandThumbDownIcon className="h-6 w-6 text-white" />
              </button>
            </div>
          )}
          {outputImages.length > 0 && selectedImageIndex < outputImages.length && outputImages[selectedImageIndex] && (
            <div className="flex justify-center space-x-2">
              <Button
                variant="outline"
                className="px-3 py-2 bg-blue-600 text-white"
                onClick={handleDownload}
              >
                Download
              </Button>
              <Button
                variant="outline"
                className="px-3 py-2 bg-green-600 text-white"
                onClick={handleShare}
              >
                Share
              </Button>
              <Button
                variant="outline"
                className="px-3 py-2 bg-purple-600 text-white"
                onClick={handleListOnMarketplace}
              >
                List on Marketplace
              </Button>
            </div>
          )}

          {/* Processed Images Grid */}
          {(outputImages.some(img => img !== undefined) || Object.keys(processingImages).length > 0) && (
            <div className="mb-4">
              <div className="flex justify-between items-center mb-2">
                <h3 className="text-sm font-medium text-white">Processed Images</h3>
                {Object.keys(processingImages).length > 0 && (
                  <span className="text-xs text-blue-400">
                    Processing {Object.keys(processingImages).length} image(s)...
                  </span>
                )}
              </div>
              <div className="grid grid-cols-2 gap-2">
                {inputImages.map((inputImg, index) => {
                  const isProcessed = outputImages[index] !== undefined;
                  const isProcessing = processingImages[index];
                  
                  if (!isProcessed && !isProcessing) return null;
                  
                  let thumbnailUrl;
                  try {
                    thumbnailUrl = isProcessed 
                      ? outputImages[index] 
                      : URL.createObjectURL(inputImg);
                  } catch (error) {
                    console.error("Error creating object URL for grid thumbnail:", error);
                    return null;
                  }
                  
                  return (
                    <div 
                      key={index}
                      className={`relative border-2 rounded overflow-hidden h-32 cursor-pointer ${
                        selectedImageIndex === index 
                          ? 'border-blue-500' 
                          : isProcessed 
                            ? 'border-green-500' 
                            : 'border-yellow-500'
                      }`}
                      onClick={() => setSelectedImageIndex(index)}
                    >
                      <img
                        src={thumbnailUrl}
                        alt={`${isProcessed ? 'Processed' : 'Processing'} ${index + 1}`}
                        className={`w-full h-full object-cover ${isProcessing ? 'opacity-50' : ''}`}
                      />
                      {isProcessing && (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className="bg-black bg-opacity-30 rounded-full p-3">
                            <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          </div>
                        </div>
                      )}
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-xs p-1 text-center">
                        {isProcessed ? `Image ${index + 1}` : 'Processing...'}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Popup for negative feedback */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">We're sorry you didn't like the result</h2>
              <button onClick={handleClosePopup} className="text-gray-500 hover:text-gray-700">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <p className="mb-4">Would you like to see some tips to get better results?</p>
            <div className="flex justify-end space-x-2">
              <Button
                variant="outline"
                className="px-3 py-2 border-gray-300 text-gray-700"
                onClick={handleClosePopup}
              >
                No, thanks
              </Button>
              <Button
                className="px-3 py-2 bg-blue-600 text-white"
                onClick={handleSeeTips}
              >
                Yes, show me tips
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}