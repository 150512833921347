import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "./TextField";
import Button from "./Button";
import LoadingIcon from "./LoadingIcon";
import { useAuth } from "./../util/auth";

function AuthForm(props) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formError, setFormError] = useState(null);
  const { handleSubmit, register, errors, getValues } = useForm();

  const submitHandlersByType = {
    signin: async ({ email, pass }) => {
      try {
        const user = await auth.signin(email, pass);
        props.onAuth(user);
      } catch (error) {
        setFormError(error.message);
        throw error;
      }
    },
    signup: async ({ email, pass }) => {
      try {
        const data = await auth.signup(email, pass);
        // Automatically authenticate the user after signup
        if (data.user) {
          props.onAuth(data.user);
        }
      } catch (error) {
        setFormError(error.message);
        throw error;
      }
    },
    forgotpass: async ({ email }) => {
      try {
        await auth.sendPasswordResetEmail(email);
        setPending(false);
        props.onFormAlert({
          type: "success",
          message: "Password reset email sent",
        });
      } catch (error) {
        setFormError(error.message);
        throw error;
      }
    },
    changepass: async ({ pass }) => {
      try {
        await auth.confirmPasswordReset(pass);
        setPending(false);
        props.onFormAlert({
          type: "success",
          message: "Your password has been changed",
        });
      } catch (error) {
        setFormError(error.message);
        throw error;
      }
    },
  };

  const onSubmit = async ({ email, pass }) => {
    setPending(true);
    setFormError(null);
    try {
      await submitHandlersByType[props.type]({ email, pass });
    } catch (error) {
      setPending(false);
      props.onFormAlert({
        type: "error",
        message: error.message,
      });
    }
  };

  return (
    <form className="space-y-3" onSubmit={handleSubmit(onSubmit)}>
      {formError && <div className="text-red-500">{formError}</div>}
      {["signup", "signin", "forgotpass"].includes(props.type) && (
        <TextField
          type="email"
          id={`${props.type}-email`}
          name="email"
          placeholder="Email"
          error={errors.email}
          inputRef={register({
            required: "Please enter an email address",
          })}
          autoComplete="email"
        />
      )}

      {["signup", "signin", "changepass"].includes(props.type) && (
        <TextField
          type="password"
          id={`${props.type}-pass`}
          name="pass"
          placeholder="Password"
          error={errors.pass}
          inputRef={register({
            required: "Please enter a password",
          })}
          autoComplete={props.type === "changepass" ? "new-password" : "current-password"}
        />
      )}

      {["signup", "changepass"].includes(props.type) && (
        <TextField
          type="password"
          id={`${props.type}-confirmPass`}
          name="confirmPass"
          placeholder="Confirm Password"
          error={errors.confirmPass}
          inputRef={register({
            required: "Please enter your password again",
            validate: (value) => {
              if (value === getValues().pass) {
                return true;
              } else {
                return "This doesn't match your password";
              }
            },
          })}
          autoComplete="new-password"
        />
      )}

      <Button type="submit" size="lg" disabled={pending} isBlock={true}>
        {pending && <LoadingIcon className="w-6" />}
        {!pending && <>{props.buttonAction}</>}
      </Button>
    </form>
  );
}

export default AuthForm;