import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Meta from "./../components/Meta";
import MarketplaceItems from "./../components/MarketplaceItems";
import { HeroSection, QuoteSection, GallerySection, DesignerSection, TestimonialsSection } from "./../components/MarketplaceSections";
import MarketplaceItemForm from "./../components/MarketplaceItemForm";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MarketplacePage() {
  const itemsRef = useRef(null);
  const query = useQuery();
  const [image, setImage] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const scrollToItems = () => {
    if (itemsRef.current) {
      itemsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const imageUrl = query.get("image");
    if (imageUrl) {
      setImage(imageUrl);
      setShowForm(true); // Automatically show the form if an image is prepopulated
    }
  }, [query]);

  const handleFormDone = () => {
    setShowForm(false);
    setImage(null);
  };

  return (
    <>
      <Meta 
        title="FurniSnapp Marketplace | Buy & Sell Furniture"
        description="Browse and sell furniture on the FurniSnapp Marketplace. Find unique pieces, post your own listings, and connect with buyers and sellers."
      />
      <div className="space-y-16 mt-8">
        <HeroSection onShopNowClick={scrollToItems} />
        <QuoteSection />
        <GallerySection />
        <DesignerSection />
        <TestimonialsSection />
      </div>
      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-lg w-full">
            <MarketplaceItemForm prepopulatedImage={image} onDone={handleFormDone} />
          </div>
        </div>
      )}
      <div ref={itemsRef}>
        <MarketplaceItems />
      </div>
    </>
  );
}

export default MarketplacePage;
