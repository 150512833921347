import React, { useState, useEffect } from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b py-4">
      <button onClick={() => setIsOpen(!isOpen)} className="flex justify-between w-full text-left text-lg font-medium">
        {question}
        {isOpen ? <MinusIcon className="w-6 h-6" /> : <PlusIcon className="w-6 h-6" />}
      </button>
      {isOpen && <p className="mt-2 text-sm text-muted-foreground">{answer}</p>}
    </div>
  );
}

function FaqSection(props) {
  const faqItems = [
    {
      question: "Who created FurniSnapp?",
      answer: "FurniSnapp was founded by a software engineer who noticed a huge pain point in the furniture selling process - staging. Since launching the platform, FurniSnapp has grown to become the largest community for flippers, helping thousands boost their sales from flips. The company was recently acquired and is under new management."
    },
    {
      question: "What kind of support is provided?",
      answer:
        "We provide customer support via email, and aim to reply to all requests within 24 hours. Those with the 'Flipper' plan and above will get priority support. Please email any questions or concerns to info@furnisnapp.com",
    },
    {
      question: "Can I cancel my plan at any time?",
      answer:
        "Yes, you will have access to the platform and your remaining credits up until your subscription expires.",
    },
    {
      question: "Do my credits carry over if I don't use them all?",
      answer:
        "Yes, you won't lose any of your credits at the end of the month, even if you don't use them all. Your unused credits will carry over to the next month.",
    },
    {
      question: "If I upgrade my plan, what will happen to my existing credits?",
      answer:
        "You will keep all of your existing credits, and we will also add new credits to your account right after upgrading. You will get an additional 25, 50, or 450 credits depending on the plan that you choose.",
    },
    {
      question: "What is furniture flipping?",
      answer:
        "Furniture flipping is the process of buying used or vintage furniture, restoring or upcycling it, and then selling it for a profit. It's a popular way to earn money while being creative and environmentally conscious.",
    },
    {
      question: "How does AI staging help sell furniture faster?",
      answer:
        "AI staging uses artificial intelligence to create realistic, professionally staged images of your furniture in appealing room settings. This helps potential buyers visualize the piece in their own home, making it more attractive and likely to sell quickly. By showcasing your furniture in a variety of settings, you can appeal to a broader audience and increase the chances of a quick sale.",
    },
    {
      question: "What types of furniture work best with FurniSnapp?",
      answer:
        "FurniSnapp works well with all types of furniture, including chairs, tables, dressers, sofas, and more. However, it's particularly effective for larger pieces that benefit from being shown in a room context.",
    },
    {
      question: "How can I take good photos of my furniture for AI staging?",
      answer:
        "For best results, take photos in good natural light, use a plain background if possible, and capture the furniture from multiple angles. Make sure the entire piece is visible and in focus.",
    },
    {
      question: "Can I use FurniSnapp for my professional furniture business?",
      answer:
        "Absolutely! Our Premium Plan is designed for professionals who flip furniture full-time. It offers a higher number of credits and additional features to support your business needs.",
    },
    {
      question: "How does FurniSnapp compare to traditional furniture staging?",
      answer:
        "FurniSnapp offers several advantages over traditional staging: it's faster, more cost-effective, and allows you to showcase your furniture in multiple settings without physically moving it. This can save you significant time and money in your flipping process.",
    },
    {
      question: "Is FurniSnapp suitable for beginners in furniture flipping?",
      answer:
        "Yes, FurniSnapp is great for beginners! It can help you create professional-looking listings even if you're just starting out. Plus, our community and resources can provide valuable tips and support as you learn the ropes of furniture flipping.",
    },
  ];

  useEffect(() => {
    // Create the script element for FAQ schema
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    const faqSchema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": faqItems.map(item => ({
        "@type": "Question",
        "name": item.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": item.answer
        }
      }))
    };
    script.innerHTML = JSON.stringify(faqSchema);

    // Append the script to the document head
    document.head.appendChild(script);

    // Clean up function to remove the script when component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);  // Empty dependency array means this effect runs once on mount

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="max-w-4xl mx-auto p-6">
        <SectionHeader
          title={props.title || "Your questions, answered"}
          subtitle={props.subtitle}
          strapline={props.strapline || "FAQS"}
          className="mb-4"
        />
        <div className="space-y-4">
          {faqItems.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </Section>
  );
}

function MinusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
    </svg>
  );
}

function PlusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M5 12h14" />
      <path d="M12 5v14" />
    </svg>
  );
}

export default FaqSection;
