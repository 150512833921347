import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaSection from "./../components/CtaSection";

function HomePage(props) {
  // Define structured data for rich results
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "FurniSnapp",
    "description": "AI-Powered Furniture Staging for Furniture Flippers & Resellers",
    "url": "https://furnisnapp.com/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://furnisnapp.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    },
    "offers": {
      "@type": "Offer",
      "description": "Professional AI Furniture Staging in Seconds",
      "price": "0",
      "priceCurrency": "USD"
    }
  };

  // Define keywords for SEO
  const keywords = "furniture flipping, AI staging, furniture staging, virtual staging, furniture photography, furniture marketplace, furniture reselling, flip furniture, furniture business, upcycling furniture";

  return (
    <>
      {/* We'll use the Meta component for all meta tags */}
      <Meta 
        title="FurniSnapp | Turn Ordinary Furniture into High-Value Masterpieces with AI"
        description="Sell furniture 2x faster & for 30% more with FurniSnapp's AI staging. Transform ordinary pieces into showroom-quality photos in seconds. No photography skills needed!"
        keywords={keywords}
        canonical="https://furnisnapp.com"
        ogType="website"
        ogImage="/images/social-image.jpg"
        ogUrl="https://furnisnapp.com"
        twitterCard="summary_large_image"
        twitterImage="/images/social-image.jpg"
        structuredData={structuredData}
      />
      
      <main id="main-content">
        <HeroSection2
          title="Sell Furniture 2X Faster & For 30% More With AI Staging"
          subtitle="Stop losing sales with amateur photos. FurniSnapp transforms your basic furniture pics into professional, magazine-worthy images in just 15 seconds. No photography skills required."
          strapline="The Secret Weapon of Top Furniture Flippers"
          size="lg"
          bgColor="bg-blue-900"
          bgImage="/hero-background.jpg"
          bgImageOpacity={0.7}
          textColor="text-white"
        />
        
        <section id="benefits" aria-labelledby="benefits-heading">
          <h2 id="benefits-heading" className="sr-only">Benefits of FurniSnapp</h2>
          {/* Benefits content could be added here */}
        </section>
        
        <TestimonialsSection
          title="Real Results from Real Flippers"
          subtitle="Join over 5,000 furniture entrepreneurs who've increased their profits by an average of 27% with FurniSnapp"
          strapline="Success Stories"
          size="md"
          bgColor="bg-white"
          bgImage=""
          bgImageOpacity={1}
          textColor=""
        />
        
        <CtaSection
          title={
            <>
              Transform Your First Piece <span className="text-blue-600">Free</span>!
            </>
          }
          subtitle="Try FurniSnapp now and get your first transformation free. See how our AI can turn your ordinary furniture photos into showstopping listings that sell faster and for more money. No credit card required."
          strapline="Ready to 2X Your Furniture Profits?"
          size="md"
          bgColor="bg-gray-100"
          bgImage=""
          bgImageOpacity={1}
          textColor=""
        />
      </main>
    </>
  );
}

export default HomePage;