import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import FaqSection from "./../components/FaqSection";
import Logo from "./../components/Logo";

function PricingPage(props) {
  return (
    <>
      <Meta 
        title="FurniSnapp Pricing | AI Furniture Staging Plans"
        description="Choose the perfect FurniSnapp plan for your furniture flipping business. Get AI-powered staging, boost sales, and maximize profits. View our competitive pricing now."
      />
      <PricingSection
        title="Choose the best plan"
        subtitle="Get to know our premium solutions and get your start up off the ground in no time. All unused credits accumulate every month, so you'll never lose what you don't use."
        strapline="Pricing"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
      />
      <Logo />
      <FaqSection
        title="Frequently Asked Questions"
        subtitle=""
        strapline="We answer"
        size="md"
        bgColor="bg-white"
        bgImage=""
        bgImageOpacity={1}
        textColor=""
        showSupportButton={true}
        supportUrl="https://zendesk.com"
      />
    </>
  );
}

export default PricingPage;