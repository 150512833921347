import React, { useState } from "react";
import Section from "./Section";
import Button from "./Button";
import { useAuth } from "./../util/auth";
import { redirectToCheckout } from "./../util/stripe";
import { Link, useRouter } from "./../util/router";
import { CheckCircleIcon } from '@heroicons/react/24/solid';

function PricingSection(props) {
  const auth = useAuth();
  const router = useRouter();
  const [error, setError] = useState(null);

  const plans = [
    {
      id: "base",
      name: "Base Plan",
      price: "8",
      billing: "billed monthly",
      description: "Designed for people making images for fun",
      features: [
        "Create 25 images/mo",
        "Unused credits accumulate monthly"
      ],
      popular: false,
    },
    {
      id: "flipper",
      name: '"Flipper" Plan',
      price: "9",
      billing: "billed monthly",
      description: "Designed for flippers looking to make more money from their furniture projects",
      features: [
        "Create 50 images/mo",
        "Unused credits accumulate monthly",
        "Download images in HD quality",
        "Post photos directly to marketplaces like Facebook and Etsy",
        "Early Access to New Features",
        "Priority Support + Faster Response Times",
      ],
      popular: true,
    },
    {
      id: "premium",
      name: "Premium Plan",
      price: "79",
      billing: "billed monthly",
      description: "Designed for professionals who flip furniture full-time",
      features: [
        "Create 450 images/mo",
        "Unused credits accumulate monthly",
        "Includes everything in the Furniture Flipper Plan",
      ],
      popular: false,
    },
  ];

  const handleSelectPlan = async (planId) => {
    try {
      setError(null);
      if (auth.user) {
        await redirectToCheckout(planId);
      } else {
        router.push(`/auth/signup?next=/purchase/${planId}`);
      }
    } catch (error) {
      console.error("Error in handleSelectPlan:", error);
      setError(error.message);
    }
  };

  return (
    <Section
      size={props.size}
      bgColor={props.bgColor}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      textColor={props.textColor}
    >
      <div className="flex flex-col items-center p-8 space-y-12">
        <div className="text-center max-w-3xl">
          <h2 className="text-sm font-semibold text-blue-600 uppercase tracking-wide mb-3">Pricing</h2>
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            Join the <span className="text-blue-600">largest</span> community of furniture flippers
          </h1>
          <p className="text-xl text-gray-600">Stage more backgrounds, sell more pieces, and boost your profits</p>
          <Button
            size="lg"
            variant="primary"
            className="mt-8 bg-blue-600 hover:bg-blue-700 text-white"
            onClick={() => handleSelectPlan('flipper')}
          >
            Start Your Free Trial
          </Button>
        </div>
        
        {error && (
          <div className="text-red-500 text-center bg-red-100 p-4 rounded-lg">
            Error: {error}
          </div>
        )}

        <div className="grid gap-8 md:grid-cols-3 w-full max-w-6xl">
          {plans.map((plan, index) => (
            <div key={index} className={`w-full border rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl ${plan.popular ? 'border-blue-500 scale-105' : 'border-gray-200'}`}>
              {plan.popular && (
                <div className="bg-blue-600 text-white text-center py-2 rounded-t-lg font-semibold">MOST POPULAR</div>
              )}
              <div className="text-center p-6">
                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <p className="text-4xl font-bold mb-1">${plan.price}<span className="text-lg font-normal text-gray-500">/mo</span></p>
                <p className="text-gray-500 mb-4">{plan.billing}</p>
                <p className="text-gray-600 mb-6">{plan.description}</p>
                <Button
                  onClick={() => handleSelectPlan(plan.id)}
                  variant={plan.popular ? "primary" : "outline"}
                  className={`w-full ${
                    plan.popular
                      ? "bg-blue-600 hover:bg-blue-700 text-white"
                      : "text-blue-600 border-blue-600 hover:bg-blue-50"
                  }`}
                >
                  {auth.user ? "Select plan" : "Start free trial"}
                </Button>
              </div>
              <div className="p-6 bg-gray-50 rounded-b-lg">
                <ul className="space-y-3">
                {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                      <span className={featureIndex === 0 ? "font-semibold" : ""}>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="text-center mt-8 bg-blue-50 p-4 rounded-lg max-w-3xl">
          <p className="text-blue-800 font-medium">All unused credits accumulate every month, so you'll never lose what you don't use!</p>
        </div>

        <div className="text-center mt-12">
          <p className="text-lg mb-4">Not sure which plan is right for you?</p>
          <Button
            component={Link}
            to="mailto:info@furnisnapp.com"
            variant="outline"
            className="text-blue-600 border-blue-600 hover:bg-blue-50"
          >
            Contact us
          </Button>
        </div>
      </div>
    </Section>
  );
}

export default PricingSection;