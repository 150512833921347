import React, { useState, useRef, useEffect } from "react";
import { Link, useRouter } from "./../util/router";
import Button from "./Button";
import { useAuth } from "./../util/auth";

function Navbar(props) {
  const auth = useAuth();
  const router = useRouter();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogout = async () => {
    await auth.signout();
    router.push("/");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const NavLinks = () => (
    <>
      <Link to="/" className="hover:text-gray-700">
        Home
      </Link>
      <Link to="/pricing" className="hover:text-gray-700">
        Pricing
      </Link>
      <Link to="/marketplace" className="hover:text-gray-700">
        Marketplace
      </Link>
    </>
  );

  return (
    <header className="bg-white">
      <div className="flex items-center justify-between p-4" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        {/* Logo that navigates to the home page when clicked */}
        <Link to="/" className="flex items-center">
          <img src="/logo.png" alt="Company Logo" className="h-8 w-auto" />
        </Link>
        
        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center gap-4">
          <nav className="flex items-center gap-6 text-lg font-medium text-gray-500">
            <NavLinks />
          </nav>
          {!auth.user ? (
            <div className="flex items-center gap-2">
              <Button
                component={Link}
                to="/auth/signin"
                variant="outline"
                className="text-blue-600 border-blue-600 hover:bg-blue-50"
              >
                Log in
              </Button>
              <Button
                component={Link}
                to="/auth/signup"
                className="bg-blue-600 text-white hover:bg-blue-700"
              >
                Sign up
              </Button>
            </div>
          ) : (
            <div className="relative" ref={dropdownRef}>
              <button onClick={toggleDropdown} className="flex items-center">
                <img
                  src={auth.user.avatar || "/default-avatar.jpg"}
                  alt="User Avatar"
                  className="h-8 w-8 rounded-full"
                />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <Link
                    to="/settings/general"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    My Account
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Log Out
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Mobile hamburger icon */}
        <button className="md:hidden" onClick={toggleSidebar}>
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>

      {/* Mobile Sidebar */}
      <div className={`fixed inset-y-0 right-0 z-50 w-64 bg-white shadow-lg transform ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:hidden`}>
        <div className="flex justify-end p-4">
          <button onClick={toggleSidebar}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <nav className="flex flex-col gap-4 p-4 text-lg font-medium text-gray-500">
          <NavLinks />
          {!auth.user ? (
            <>
              <Link to="/auth/signin" className="hover:text-gray-700">Log in</Link>
              <Link to="/auth/signup" className="hover:text-gray-700">Sign up</Link>
            </>
          ) : (
            <>
              <Link to="/settings/general" className="hover:text-gray-700">My Account</Link>
              <button onClick={handleLogout} className="text-left hover:text-gray-700">Log Out</button>
            </>
          )}
        </nav>
      </div>
    </header>
  );
}

export default Navbar;